import {
  FETCH_COMPANY,
 } from '../actions/companyActions';

const INITIAL_STATE = {
  data: {
    rateUSDtoMYR: "4.13",
    companyName: "Pracworks",
    companyNumber: "SA0324572-H",
    address: "NO. 12, JALAN HI-TECH 6/1, KAWASAN PERINDUSTRIAN HI-TECH 6, 43500, SEMENYIH, SELANGOR, MALAYSIA",
    state: "",
    country: "Malaysia",
    postalCode: "40150",
    phoneNumber: "+60163950893",
    countryCode: "MY",
    personName: "Yun Kit",
    stateOrProvinceCode: "14"
  },
}

export default function companyReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_COMPANY:
      return {
        ...state,
        data:action.data,
      }
    default:
      return state

  }
}