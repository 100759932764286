import React, { useState } from "react";
import {connect} from 'react-redux';
import { updateDetails } from '../redux/actions/roleActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Notifications from "../components/Extra/Notifications.js"

// core components
import UserHeader from "../components/Headers/UserHeader.js";

import client from "../feathers"

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  gender: Yup.string()
    .required('Gender is required'),
  birth: Yup.string()
    .required('Birth is required'),
  address: Yup.string()
    .required('Address is required'),
  state: Yup.string()
    .required('State is required'),
  country: Yup.string()
    .required('Country is required'),
  postalCode: Yup.string()
    .required('PostalCode is required'),
  phoneNumber: Yup.string()
    .required('Contact No. is required'),
})

const Profile = (props) => {
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  const selectCountry = (val,  setFieldValue) => {
    let code = CountryRegionData.find(e => e[0] === val)[1]
    setFieldValue("country", val)
    setFieldValue("countryCode", code)
    setFieldValue("state", '')
    setFieldValue("stateOrProvinceCode", '')
  }

  const selectState = (val, country, setFieldValue) => {
    let regionData = (CountryRegionData.find(e => e[0] === country)[2]).split("|")
    let stateOrProvinceCode = regionData.find(e => e.split('~')[0] === val)
    setFieldValue("state", val)
    setFieldValue("stateOrProvinceCode", stateOrProvinceCode.split('~')[1])
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  const onSubmit = async(value) => {
    if(props.userInfo._id){
      client.authenticate()
      .then(()=>{
        return client.service('users').patch(props.userInfo._id, value)
      })
      .then((res) => {
        props.updateDetails(res)
        notificationOpen(true, 'success', "User " + res.username + " Updated Successfully!")
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  return (
    <>
      <UserHeader name={
        props.userInfo.name?props.userInfo.name:
        (
        (props.userInfo.firstName?props.userInfo.firstName:"")
        + " " + 
        (props.userInfo.lastName?props.userInfo.lastName:"")
        + " "
        )
      } />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" md="12">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {/* <img
                        alt="..."
                        className="rounded-circle"
                        src={
                          require("../assets/img/theme/team-4-800x800.jpg")
                            .default
                        }
                      /> */}
                      <div
                        className="img rounded-circle overflow-hidden bg-white position-relative ml-auto mr-auto mt--5"
                        style={{ height: 180, width: 180 }}
                      >
                        <img
                          alt="entreship sol"
                          className="w-100"
                          style={{transform:'initial', position:'initial'}}
                          src={(props.userInfo.profileImage?props.userInfo.profileImage.fileUrl: "")? props.userInfo.profileImage.fileUrl: "/ship.png"}
                          />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
              {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div>
              </CardHeader> */}
              <CardBody className="pt-0 pt-md-4">
                {/* <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div>
                    </div>
                  </div>
                </Row> */}
                <div className="text-center">
                  <h2>
                    {
                      props.userInfo.name?props.userInfo.name:
                      (
                        (props.userInfo.firstName?props.userInfo.firstName:"")
                        + " " + 
                        (props.userInfo.lastName?props.userInfo.lastName:"")
                        + " "
                      )
                    }
                    <span className="font-weight-light">, {props.userInfo.birth?new Date().getFullYear() - Number(props.userInfo.birth.substring(0, 4)):"-"}</span>
                  </h2>
                  <div className="h4">
                    <i className="ni location_pin mr-2" />
                    {props.userInfo.birth}
                  </div>
                  <div className="h4">
                    <i className="ni education_hat mr-2" />
                    {"Gender - " + (props.userInfo.gender?props.userInfo.gender: "").toUpperCase()}
                  </div>
                  <div className="h4 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {props.userInfo.description?props.userInfo.description:"-"}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                    firstName: props.userInfo.firstName?props.userInfo.firstName:"",
                    lastName: props.userInfo.lastName?props.userInfo.lastName:"",
                    gender: props.userInfo.gender?props.userInfo.gender:"",
                    birth: props.userInfo.birth?props.userInfo.birth:"",
                    address: props.userInfo.address?props.userInfo.address:"",
                    country: props.userInfo.country?props.userInfo.country:"",
                    countryCode: props.userInfo.countryCode?props.userInfo.countryCode:"",
                    stateOrProvinceCode: props.userInfo.stateOrProvinceCode?props.userInfo.stateOrProvinceCode:"",
                    state: props.userInfo.state?props.userInfo.state:"",
                    postalCode: props.userInfo.postalCode?props.userInfo.postalCode:"",
                    phoneNumber: props.userInfo.phoneNumber?props.userInfo.phoneNumber:"",
                    description: props.userInfo.description?props.userInfo.description:"",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {propsChild => {
                    const {
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      handleChange,
                      handleSubmit,
                    } = propsChild;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <h6 className="heading-small text-muted mb-4">
                        User information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Username
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={props.userInfo.username?props.userInfo.username:""}
                                placeholder="Username"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={props.userInfo.email?props.userInfo.email:""}
                                placeholder="jesse@example.com"
                                type="email"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="firstName"
                              >
                                First name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                placeholder="First name"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="lastName"
                              >
                                Last name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                placeholder="Last name"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="gender"
                              >
                                Gender
                              </label>
                                <Input 
                                  className="form-control-alternative"
                                  placeholder="Gender"
                                  type="select" 
                                  id="gender" 
                                  value={values.gender}
                                  onChange={handleChange}
                                >
                                <option>male</option>
                                <option>female</option>
                                <option>other</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="birth"
                              >
                                Birth
                              </label>
                              <Input 
                                className="form-control-alternative"
                                placeholder="Birth"
                                type="date"
                                id="birth"
                                value={values.birth}
                                onChange={handleChange} 
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Contact information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Address
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="address"
                                value={values.address}
                                onChange={handleChange} 
                                placeholder="Home Address"
                                type="text"
                              />
                              {errors.address && touched.address && (
                                <div className="input-feedback">{errors.address}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="country"
                              >
                                Country
                              </label>
                              <CountryDropdown
                                value={values.country}
                                id="country"
                                name="country" 
                                classes="form-control-alternative form-control"  
                                onChange={(val) => selectCountry(val, setFieldValue)} 
                                />
                              {errors.country && touched.country && (
                                <div className="input-feedback">{errors.country}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="state"
                              >
                                State
                              </label>
                              <RegionDropdown
                                country={values.country}
                                id="state"
                                value={values.state}
                                classes="form-control-alternative form-control"
                                onChange={(val) => selectState(val, values.country, setFieldValue)} />
                              {errors.state && touched.state && (
                                <div className="input-feedback">{errors.state}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="postalCode"
                              >
                                Postal code
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="postalCode"
                                value={values.postalCode}
                                onChange={handleChange} 
                                onWheel={ event => event.currentTarget.blur() }
                                placeholder="Postal code"
                                type="number"
                              />
                              {errors.postalCode && touched.postalCode && (
                                <div className="input-feedback">{errors.postalCode}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <label>Contact No.</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Contact No."
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange} 
                            type="text"
                          />
                        </FormGroup>
                      </div>
                      <hr className="my-4" />
                      {/* Description */}
                      <h6 className="heading-small text-muted mb-4">About me</h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <label>About Me</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="A few words about you ..."
                            rows="4"
                            id="description"
                            value={values.description}
                            onChange={handleChange} 
                            type="textarea"
                          />
                        </FormGroup>
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
};


const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  updateDetails: updateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);