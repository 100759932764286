import React, { useRef, useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep"
import { Formik } from 'formik';
import * as Yup from 'yup';
import isEmpty from "lodash/isEmpty"
import arrayMove from "array-move"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Modal,
  Row,
  Col
} from "reactstrap";

import Variations from "./Variations.js";

import client from "../../feathers.js"
import { storage } from "../../firebase";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Product Name is required'),
  code: Yup.string()
    .required('Product Code is required'),
  netAmount: Yup.number()
    .required('Product Net Amount is required'),
  packageWeight: Yup.number()
    .required('Package Weight is required'),
})

const ProductModalEdit = (props) => {
  const bgFileEl = useRef()
  // const [ availability, setAvailability ] = useState('In Stock')
  const [ status, setStatus ] = useState(true)
  const [ fileArray, setFileArray ] = useState([])
  const [ varType, setVarType ] = useState('anodizing_color')
  const [ varValue, setVarValue ] = useState('')
  const [ varAmount, setVarAmount ] = useState(0)
  const [ variationArray, setVariationArray ] = useState([])
  const [ nameFocused, setNameFocused ] = useState(false)
  const [ codeFocused, setCodeFocused ] = useState(false)
  const [ amountFocused, setAmountFocused ] = useState(false)
  const [ progress, setProgress ] = useState(0)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if(!isEmpty(props.dataEdit)){
      setFileArray(props.dataEdit.productImg)
      // setAvailability(props.dataEdit.availability)
      setStatus(props.dataEdit.status)
      setVariationArray(props.dataEdit.variations?props.dataEdit.variations:[])
    }
  }, [props.modalVisible, props.dataEdit, props.dataEdit.status])

  const handleImgChange = (e) => {
    setProgress(0)

    let singleFile = e.target.files[0]
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      let objectImg = URL.createObjectURL(e.target.files[0])
      let imgData = {
        name: singleFile.name,
        file: modified,
        preview: objectImg
      }
      setFileArray(fileArray.concat(imgData))

      return e.target.value = null
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  const removeFileInedx = (index) => {
    URL.revokeObjectURL(fileArray[index].preview)
    if(fileArray[index].fileName){
      handleDelete(fileArray[index].fileName)
    }
    let result = cloneDeep(fileArray)
    result.splice(index, 1)
    setFileArray(result)
  }

  const handleDelete = (currentFileName) => {
    // Create a reference to the file to delete
    var desertRef = storage.ref("productImages").child(currentFileName);

    // Delete the file
    desertRef.delete().then(function() {
      // File deleted successfully
      console.log('File deleted successfully')
    }).catch(function(error) {
      // Uh-oh, an error occurred!
      console.log('Uh-oh, an error occurred!')
    });
  }

  const removeFile = () => {
    fileArray.map((v) => {
      return URL.revokeObjectURL(v.preview)
    })
    setFileArray([])
  }

  const handleUpload = (file) => {
    setProgress(0)
    return new Promise(resolve => {
      let uploadTask = storage.ref(`productImages/${file.name}`).put(file)
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {
          console.log(error);
        },
        ()=> {
          storage
            .ref("productImages")
            .child(file.name)
            .getDownloadURL()
            .then(url => {
              return resolve(url)
            })
        }
      )
    })
  }

  const imageProcess = (file) => {
    let result = handleUpload(file).then(v => v)
    return result
  }

  const onSubmit = async(value) => {
    setLoading(true)
    // value.availability = availability
    value.status = status
    value.variations = variationArray
    
    if(fileArray.length > 0){
      let cloneFileArr = cloneDeep(fileArray)
      let processImg = await cloneFileArr.map(async(v) => {
        if(v.file){
          if(v.file.name){
            let imgUrl = await imageProcess(v.file)
            v.fileUrl = imgUrl
            v.fileName = v.file.name
            delete v.file
            delete v.preview
            delete v.name
            return v
          }else{
            return v
          }
        }else{
          return v
        }
      })
      
      const result = await Promise.all(processImg)
      value.productImg = result
    }
    
    client.authenticate()
    .then(()=>{
      return client.service('products').patch(props.dataEdit._id, value)
    })
    .then((res) => {
      props.toggleModal()
      props.updateProducts(res)
      props.notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
      removeFile()
      setVariationArray([])
      setLoading(false)
    })
    .catch((err)=>{
      removeFile()
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const pushVariation = (type, value, amount) => {
    if(type && value && (!isNaN(amount) && (amount >= 0))){
      let result = variationArray.concat({
        type: (type).toLowerCase(), 
        value: (value).toLowerCase(), 
        amount: amount?amount:0,
      })
      setVariationArray(result)
      setVarValue('')
      setVarAmount(0)
    }
  }

  const variationMove = (array, index, indexMove) => {
    setVariationArray(arrayMove(array, index, indexMove))
  }

  const removeVariation = (index) => {
    let result = variationArray.filter((v, i) => i !== index)
    setVariationArray(result)
  }

  const fileArrayMove = (array, index, indexMove) => {
    setFileArray(arrayMove(array, index, indexMove))
  }

  return (  
    <>
      <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={() => {props.toggleModal(); removeFile()}}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Product
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            name: props.dataEdit.name,
            code: props.dataEdit.code,
            netAmount: props.dataEdit.netAmount,
            availability: props.dataEdit.availability,
            description: props.dataEdit.description,
            packagingType: 'YOUR_PACKAGING',
            packageWeight: props.dataEdit.packageWeight,
            packageLength: props.dataEdit.packageLength,
            packageWidth: props.dataEdit.packageWidth,
            packageHeight: props.dataEdit.packageHeight,
            localShippingAmount: props.dataEdit.localShippingAmount,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {propsChild => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = propsChild;
            return (
              <Form role="form" onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Product Images
                    </small>
                  </div>
                  <Row className="justify-content-center">
                    {fileArray.length > 0? 
                      fileArray.map((v, i) => {
                        return (
                          <Col md="4" lg="4" key={i}>
                            <FormGroup className="text-center">
                              <Card className="mb-2 shadow-sm">
                                <CardBody className="p-0">
                                  <div className="file-component">
                                    <div className="file-overlay">
                                      <div className="file-buttons">
                                        <div className="page-item" onClick={() => fileArrayMove(fileArray, i, i-1)}>
                                          <button type="button" className="page-link text-white btn-primary bg-primary d-inline-block">
                                            <i className="ni ni-bold-up"></i>
                                          </button>
                                        </div>
                                        <div className="page-item" onClick={() => fileArrayMove(fileArray, i, i+1)}>
                                          <button type="button" className="page-link text-white btn-primary bg-primary d-inline-block">
                                            <i className="ni ni-bold-down"></i>
                                          </button>
                                        </div>
                                        <Button
                                          className="border-0"
                                          color="danger"
                                          onClick={() => removeFileInedx(i)}
                                          >
                                          <div className="btn-inner--icon">
                                            <span className="btn-inner--text">Remove</span>
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                    <img className="w-100" src={v.preview || v.fileUrl} alt="entreship sol" />
                                  </div>
                                </CardBody>
                              </Card>
                              <span style={{ overflowWrap: 'break-word' }}>{v.name}</span>
                            </FormGroup>
                          </Col>
                        )
                      }): null}
                      {fileArray.length < 12? (
                      <>
                        <div className="file-upload">
                          <input 
                            type="file" 
                            ref={bgFileEl} 
                            onChange={(e) => handleImgChange(e)} 
                            hidden />                
                            <div className="progessBar" style={{ width: progress }}>
                          </div>
                          <Button
                            className="m-0 mb-1 w-100"
                            color="secondary"
                            onClick={() => bgFileEl.current.click()}
                          >
                            <div className="btn-inner--icon">
                              <span className="btn-inner--text">Upload Images</span>
                            </div>
                          </Button>
                        </div>
                      </>
                    ): null}
                  </Row>
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Product Details
                    </small>
                  </div>
                  <Row>
                    <Col md="6">
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: nameFocused
                        }, {
                          "has-danger": errors.name && touched.name
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Product Name"
                            type="text"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                            onFocus={e => setNameFocused(true)}
                            onBlur={e => {
                              setNameFocused(false);
                              handleBlur(e)
                            }}
                          />
                        </InputGroup>
                        {errors.name && touched.name && (
                          <div className="input-feedback">{errors.name}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: codeFocused
                        }, {
                          "has-danger": errors.code && touched.code
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-qrcode"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Product Code"
                            type="text"
                            id="code"
                            rows="3"
                            value={values.code}
                            onChange={handleChange}
                            onFocus={e => setCodeFocused(true)}
                            onBlur={e => {
                              setCodeFocused(false);
                              handleBlur(e)
                            }}
                          />
                        </InputGroup>
                        {errors.code && touched.code && (
                          <div className="input-feedback">{errors.code}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Price in USD
                    </small>
                  </div>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: amountFocused
                    }, {
                      "has-danger": errors.netAmount && touched.netAmount
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-money-coins" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Product Net Amount"
                        type="number"
                        id="netAmount"
                        rows="3"
                        value={values.netAmount}
                        onChange={handleChange}
                        onFocus={e => setAmountFocused(true)}
                        onBlur={e => {
                          setAmountFocused(false);
                          handleBlur(e)
                        }}
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </InputGroup>
                    {errors.netAmount && touched.netAmount && (
                      <div className="input-feedback">{errors.netAmount}</div>
                    )}
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Availability
                        </small>
                      </div>
                      <FormGroup className="text-center">
                        {/* <UncontrolledDropdown nav inNavbar className="w-100">
                          <DropdownToggle caret color="secondary" className="w-100">
                            {availability}
                          </DropdownToggle>
                          <DropdownMenu className="w-100">
                            <div className="editInputs-colors-scrollbar mac-scrollbar">
                              <DropdownItem 
                                className="text-capitalize" 
                                value="In Stock" 
                                onClick={() => setAvailability('In Stock')}
                              >
                                In Stock
                              </DropdownItem>
                              <DropdownItem 
                                className="text-capitalize" 
                                onClick={() => setAvailability('Out Of Stock')}
                                value="Out Of Stock" 
                              >
                                Out Of Stock
                              </DropdownItem>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                         <Input
                            className="form-control-alternative"
                            placeholder="Availability"
                            type="text"
                            id="availability"
                            value={values.availability}
                            onChange={handleChange}
                          />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Product Status
                        </small>
                      </div>
                      <FormGroup className="text-center">
                        <UncontrolledDropdown nav inNavbar className="w-100">
                          <DropdownToggle caret color="secondary" className="w-100">
                            {status?"Active":"Disabled"}
                          </DropdownToggle>
                          <DropdownMenu className="w-100">
                            <div className="editInputs-colors-scrollbar mac-scrollbar">
                              <DropdownItem 
                                className="text-capitalize" 
                                value={true} 
                                onClick={() => setStatus(true)}
                              >
                                Active
                              </DropdownItem>
                              <DropdownItem 
                                className="text-capitalize" 
                                value={false} 
                                onClick={() => setStatus(false)}
                              >
                                Disabled
                              </DropdownItem>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Variations 
                    varType={varType}
                    varValue={varValue} 
                    varAmount={varAmount} 
                    setVarType={setVarType} 
                    setVarValue={setVarValue} 
                    setVarAmount={setVarAmount} 
                    variationArray={variationArray} 
                    pushVariation={pushVariation} 
                    variationMove={variationMove}
                    removeVariation={removeVariation}
                    rateUSDtoMYR={props.rateUSDtoMYR}
                  />
                  <FormGroup>
                    <div className="mb-2">
                      <small className="text-uppercase font-weight-bold">
                        Description
                      </small>
                    </div>
                    <Input
                      className="form-control-alternative"
                      placeholder="Description"
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      autoComplete="off"
                      type="textarea"
                      rows="3"
                    />
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Packaging
                        </small>
                      </div>
                      <FormGroup className="text-center">
                        <UncontrolledDropdown nav inNavbar className="w-100">
                          <DropdownToggle caret color="secondary" className="w-100">
                            YOUR_PACKAGING
                          </DropdownToggle>
                          <DropdownMenu className="w-100">
                            <div className="editInputs-colors-scrollbar mac-scrollbar">
                              <DropdownItem 
                                className="text-capitalize" 
                                value="YOUR_PACKAGING" 
                              >
                                YOUR_PACKAGING
                              </DropdownItem>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Weight(kg.)
                        </small>
                      </div>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="Kg"
                          id="packageWeight"
                          value={values.packageWeight}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                          type="number"
                        />
                        {errors.packageWeight && touched.packageWeight && (
                          <div className="input-feedback">{errors.packageWeight}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Dimensions per package
                    </small>
                    <br/>
                    <small>
                      L x W x H (Optional)
                    </small>
                  </div>
                  <Row>
                    <Col md="4">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          L (CM)
                        </small>
                      </div>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="cm"
                          id="packageLength"
                          value={values.packageLength}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          W (CM)
                        </small>
                      </div>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="cm"
                          id="packageWidth"
                          value={values.packageWidth}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          H (CM)
                        </small>
                      </div>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="cm"
                          id="packageHeight"
                          value={values.packageHeight}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Shipping Amount In Local (RM)
                    </small>
                  </div>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-money-coins" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control-alternative"
                        placeholder="RM"
                        id="localShippingAmount"
                        value={values.localShippingAmount}
                        onChange={handleChange}
                        onWheel={ event => event.currentTarget.blur() }
                        type="number"
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {props.toggleModal(); removeFile()}}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading? (
                      <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ):null}
                    Confirm
                  </Button>
                </div>
              </Form>
              );
            }}
          </Formik>
      </Modal>
    </>
  );
}
 
export default ProductModalEdit;