//Action Types
export const FETCH_USERS = 'FETCH_USERS';
export const PUSH_USERS = 'PUSH_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';

//Action Creator
export const fetchUsers = (data) => ({
   type: FETCH_USERS,
   data: data
});

export const pushUsers = (data) => ({
    type: PUSH_USERS,
    data: data
});

export const updateUsers = (data) => ({
    type: UPDATE_USERS,
    data: data
});

export const removeUsers = (data) => ({
   type: REMOVE_USERS,
   data: data
});