import React, { useEffect } from "react";
import { jsPDF } from "jspdf";

const DownloadPdf = (props) => {
  
  useEffect(() => {
    const pdf_name = props.location.state.pdf_name;
    const html = props.location.state.html;

    var doc = new jsPDF();

    let div = document.createElement('div')
    div.innerHTML = html
    div.getElementsByTagName('table')[0].classList.remove("table-striped");

    doc.html(div, {
      html2canvas: {
        scale: .23 // default is window.devicePixelRatio
      },
      x: 10,
      y: 10,
      callback: function (doc) {
        doc.save(pdf_name + ".pdf");
      },
    });

    // document.getElementById('aaa').innerHTML = props.location.state.html

    setTimeout(() => {
      props.history.goBack()
    }, 500);
  }, [props.location.state.pdf_name, props.location.state.html, props.history])


  return (  
      <>
        <div id="aaa"></div>
      </>
  );
}
 
export default DownloadPdf;