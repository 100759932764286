import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchTransactions, pushTransactions, updateTransactions } from '../redux/actions/transactionsActions';
import moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";

import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"

const PAGESIZE = 10;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Transactions = (props) => {
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    fetchData(props.fetchTransactions, notificationOpen, 0, 1)
  }, [props.fetchTransactions])

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (fetchTransactions, notificationOpen, skip, currentPage) => {
    client.authenticate()
    .then(()=>{
      return client.service('transactions').find({
        query: {
          $skip: skip,
          $sort: {
            createdAt: -1
          }
        }
      })
    })
    .then((res) => {
      setPagination(paginationCount(res.total, currentPage))
      fetchTransactions(res.data)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }
  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.fetchTransactions, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.fetchTransactions, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.fetchTransactions, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Invoices tables</h3>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Payer Email</th>
                      <th scope="col">Payer Name</th>
                      <th scope="col">Payee Email</th>
                      <th scope="col">Payee Merchant Id</th>
                      <th scope="col">Invoice Id</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Items Amt.</th>
                      <th scope="col">Shipping Amt.</th>
                      <th scope="col">Total Amt.</th>
                      <th scope="col">Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.transactionsData.length > 0? (
                      props.transactionsData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              {v.payerEmail}
                            </th>
                            <td>{v.payerName}</td>
                            <td>{v.payeeEmail}</td>
                            <td>{v.payeeMerchantId}</td>
                            <td>{v.invoiceId}</td>
                            <td>{v.currency}</td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalItemsPaidAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalItemsPaidAmount)
                              }
                            </td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.shippingPaidAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.shippingPaidAmount)
                              }
                            </td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalPaidAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalPaidAmount)
                              }
                            </td>
                            <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                          </tr>
                        )
                      })
                    ): <span style={{padding: '0px 10px'}}>No Record Found!</span>}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  transactionsData: state.transactions.data,
});

const mapDispatchToProps = {
  fetchTransactions: fetchTransactions, 
  pushTransactions: pushTransactions, 
  updateTransactions: updateTransactions
};
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);