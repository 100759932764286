import React from "react";
// reactstrap components
import {
  Button,
  Modal
} from "reactstrap";

const ModalConfirmation = (props) => {

  const confirmModal = () => {
    props.toggleModal()
    props.modalCallback(true)
  }

  return ( 
    <>
      {/* Modal */}
      <Modal
        className="modal-dialog-centered"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {props.title?props.title: "Modal Delete"}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {props.content?props.content: "Are you sure want to delete this?"}
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            Close
          </Button>
          <Button 
            color="primary" 
            type="button"
            onClick={confirmModal}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
 
export default ModalConfirmation;