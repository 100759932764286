import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import axios from "axios";
import moment from "moment";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  // NavItem,
  // NavLink,
  // Nav,
  // Progress,
  // Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../variables/charts.js";

import DateRange from "../components/Extra/DateRange.js"
// import Header from "../components/Headers/Header.js";
import HeaderBoxes from "../components/Headers/HeaderBoxes.js";
import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"

var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

const Index = (props) => {
  // const [activeNav, setActiveNav] = useState(1);
  // const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [chartData1, setChartData1] = useState({
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Performance",
        data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
      },
    ],
  });
  const [chartData2, setChartData2] = useState({
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Sales",
        data: [25, 20, 30, 22, 17, 29],
        maxBarThickness: 10,
      },
    ],
  });
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ startDate, setStartDate ] = useState(moment().startOf('months'))
  const [ endDate, setEndDate ] = useState(moment().endOf('months'))
  const [ totalAmount, setTotalAmount ] = useState(0)
  const [ totalOrders, setTotalOrders ] = useState(0)

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    // let newDate = new Date()
    fetchData(notificationOpen, new Date(startDate), new Date(endDate))
    // eslint-disable-next-line
  }, [])

  const searchDate = (startDate, endDate) => {
    if(startDate && endDate){
      fetchData(notificationOpen, startDate._d, endDate._d)
    }
  }

  const fetchData = (notificationOpen, startDate, endDate) => {
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}fetchSalesReport`, {
        startDate: new Date(startDate).getTime(),
        endDate: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      let data1 = {
        labels: [],
        datasets: [
          {
            label: "Performance",
            data: [],
          },
        ],
      }

      let data2 = {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            maxBarThickness: 10,
          },
        ],
      }

      if(res.data.length > 0){
        res.data = sortBy(res.data, ['_id.year', '_id.month'])
        data1.labels = res.data.map(v => {
          return months[v._id.month-1]
        })
        data1.datasets[0].data = res.data.map(v => {
          return (v.totalAmount).toFixed(2)
        })
        data2.labels = res.data.map(v => {
          return months[v._id.month-1]
        })
        data2.datasets[0].data = res.data.map(v => {
          return (v.count)
        })
        setChartData1(data1)
        setChartData2(data2)
        setTotalAmount(sumBy(res.data, "totalAmount"))
        setTotalOrders(sumBy(res.data, "count"))
      }else{
        setChartData1(data1)
        setChartData2(data2)
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  // const toggleNavs = (e, index) => {
  //   e.preventDefault();
  //   setActiveNav(index);
  //   setChartExample1Data("data" + index);
  // };

  return (
    <>
      {/* <Header /> */}
      <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <span className="mask bg-gradient-default opacity-3" />
        <Container className="d-flex align-items-center" fluid>
          <Row className="w-100 justify-content-center text-center m-auto">
            <Col md="8">
              <h1 className="text-white">
                Search by Date
              </h1>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate} 
                searchDate={searchDate}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <HeaderBoxes 
          totalAmount={totalAmount}
          totalOrders={totalOrders}
          rateUSDtoMYR={props.rateUSDtoMYR}
        />
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  {/* <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div> */}
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={chartData1}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={chartData2}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/index.html</th>
                    <td>3,985</td>
                    <td>319</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/charts.html</th>
                    <td>3,513</td>
                    <td>294</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      36,49%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/tables.html</th>
                    <td>2,050</td>
                    <td>147</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 50,87%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/profile.html</th>
                    <td>1,795</td>
                    <td>190</td>
                    <td>
                      <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row> */}
      </Container>
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
};

const mapStateToProps = state => ({
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
