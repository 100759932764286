import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchProducts, pushProducts, updateProducts, moveProducts } from '../redux/actions/productsActions';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import ProductModal from "../components/Products/ProductModal.js"
import ProductModalEdit from "../components/Products/ProductModalEdit.js"

import ModalConfirmation from "../components/Extra/ModalConfirmation.js"
import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"

const PAGESIZE = 10;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Products = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ statusOnChange, setStatusOnChange ] = useState({id:'', status:true})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    fetchData(props.userId, props.fetchProducts, notificationOpen, 0, 1)
  }, [props.userId, props.fetchProducts])

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    productStatus(statusOnChange.id, statusOnChange.status)
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchProducts, notificationOpen, skip, currentPage) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('products').find({
          query: {
            userId: userId,
            $skip: skip,
            $sort: {
              dateToFirst: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage))
        fetchProducts(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  const productStatus = (_id, status) => {
    client.authenticate()
    .then(()=>{
      return client.service('products').patch(_id, {status: !status})
    })
    .then((res) => {
      props.updateProducts(res)
      notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const listItemTop = (_id) => {
    client.authenticate()
    .then(()=>{
      return client.service('products').patch(_id, {dateToFirst: new Date()})
    })
    .then((res) => {
      props.updateProducts(res)
      props.moveProducts(res)
      notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }
  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchProducts, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchProducts, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchProducts, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <span className="mask bg-gradient-default opacity-6" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col md="12">
              <p className="text-white m-0">
                Conversion rate <strong>USD</strong> to <strong>MYR</strong>
              </p>
              <h1 className="display-2 text-white">{props.rateUSDtoMYR}</h1>
              <Button
                color="secondary"
                href="/admin/setting"
              >
                Edit Rate
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Products tables</h3>
                  </Media>
                  <Media body className="text-right">
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--text">Create</span>
                    </Button>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Code</th>
                      <th scope="col">Avai</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created</th>
                      <th scope="col">Updated</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props.productsData.length > 0? (
                      props.productsData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <a
                                  href="/#"
                                  className="avatar rounded-circle mr-3"
                                  onClick={(e) => e.preventDefault()}
                                >
                                   {v.productImg.length > 0? (
                                    <div
                                      className="img rounded-circle overflow-hidden bg-white d-inline-block"
                                      style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                                    >
                                      <img
                                        alt="entreship sol"
                                        className="w-100"
                                        src={v.productImg[0].fileUrl}
                                      />
                                    </div>
                                  ):(
                                    <i className="ni ni-image"></i>
                                  )}
                                </a>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {v.name}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>
                              <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.netAmount)}</span>
                              <br/>
                              <span>{new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.netAmount*props.rateUSDtoMYR))}</span>
                            </td>
                            <td>{v.code}</td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.availability === 'In Stock'? "bg-success": "bg-danger"} />
                                {v.availability}
                              </Badge>
                            </td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.status? "bg-success": "bg-danger"} />
                                {v.status?"Active":"Disabled"}
                              </Badge>
                            </td>
                            <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td>{moment(v.updatedAt).format("MMM Do YYYY")}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => listItemTop(v._id)}
                                  >
                                    List Top
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => 
                                      {
                                        setStatusOnChange({id: v._id, status: v.status});
                                        toggleModalConfirm()
                                      }
                                    }
                                  >
                                    {v.status?"Disabled":"Enable"}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })
                    ): <span style={{padding: '0px 10px'}}>No Record Found!</span>}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <ProductModal 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
        notificationOpen={notificationOpen} 
        pushProducts={props.pushProducts}
        rateUSDtoMYR={props.rateUSDtoMYR}
      />
      <ProductModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen} 
        updateProducts={props.updateProducts}
        rateUSDtoMYR={props.rateUSDtoMYR}
      />
       <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title="Product Status"
        content={statusOnChange.status?"Are you sure want to Disable this?":"Are you sure want to Enable this?"}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 
const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  productsData: state.products.data,
  companyInfo: state.company.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
});

const mapDispatchToProps = {
  fetchProducts: fetchProducts,
  pushProducts: pushProducts,
  updateProducts: updateProducts,
  moveProducts: moveProducts
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);