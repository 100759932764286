import React from "react";
import uniqBy from "lodash/uniqBy"

// reactstrap components
import {
  Table,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";

const Variations = ({
  varType, 
  varValue, 
  varAmount, 
  setVarType, 
  setVarValue, 
  setVarAmount, 
  variationArray, 
  pushVariation, 
  variationMove,
  removeVariation,
  rateUSDtoMYR
}) => {

  return (  
    <>
      <Card className="shadow mb-3">
        <CardHeader className="bg-secondary">Variations</CardHeader>
        <CardBody>
          <Row className="mb-2">
            <Col md="4">
              <div>
                <small className="text-uppercase font-weight-bold">
                  Type
                </small>
              </div>
              {/* <UncontrolledDropdown nav inNavbar className="w-100">
                <DropdownToggle caret color="secondary" className="w-100 text-capitalize">
                  {varType.split('_').join(' ')}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  <div className="editInputs-colors-scrollbar mac-scrollbar">
                    <DropdownItem 
                      className="text-capitalize" 
                      value="anodizing_color" 
                      onClick={() => setVarType("anodizing_color")}
                    >
                      Anodizing Color
                    </DropdownItem>
                    <DropdownItem 
                      className="text-capitalize" 
                      value="carbon_fiber_finish" 
                      onClick={() => setVarType("carbon_fiber_finish")}
                    >
                      Carbon Fiber Finish
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <Input
                placeholder="exp. anodizing_color..."
                type="text"
                className="input-group-alternative"
                value={varType}
                onChange={(e) => setVarType(e.target.value)}
              />
              Exp: anodizing_color, carbon_fiber_finish
            </Col>
            <Col md="4">
              <div>
                <small className="text-uppercase font-weight-bold">
                  Value
                </small>
              </div>
              <Input
                placeholder="exp. Red, Yellow..."
                type="text"
                className="input-group-alternative"
                value={varValue}
                onChange={(e) => setVarValue(e.target.value)}
              />
            </Col>
            <Col md="4">
              <div>
                <small className="text-uppercase font-weight-bold">
                  Price in USD
                </small>
              </div>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-money-coins" />
                  </InputGroupText>
                </InputGroupAddon>
                  <Input
                    placeholder="Amount"
                    type="number"
                    className="input-group-alternative"
                    value={varAmount}
                    onChange={(e) => setVarAmount(e.target.value)}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center pt-2">
            <Col md="8">
              <FormGroup>
                <Button
                  className="m-0 mb-1 w-100"
                  color="secondary"
                  type="button"
                  onClick={() => pushVariation(varType, varValue, varAmount)}
                >
                  <div className="btn-inner--text">
                    <span>Add Variety</span>
                  </div>
                </Button>
              </FormGroup>
            </Col>
          </Row>
          {variationArray.length > 0?
            uniqBy(variationArray, "type").map((uniType, uniIndex) => {
              return (
                <div key={uniIndex} className="mt-2">
                  <Card className="bg-default shadow">
                    <CardHeader className="bg-transparent border-0 text-white text-uppercase">
                      {uniType.type.split('_').join(' ')}
                    </CardHeader>
                    <Table className="align-items-center table-dark table-flush">
                      <thead className="thead-dark">
                        <tr>
                          <th></th>
                          <th>Type</th>
                          <th>Value</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {variationArray.map((v, i) => {
                          if(v.type === uniType.type){
                            return (
                              <tr key={i} className="text-uppercase">
                                <th scope="row">
                                  <div className="cursor-pointer" onClick={()=>variationMove(variationArray, i, i-1)}>
                                    <i className="ni ni-bold-up"></i>
                                  </div>
                                  <div className="cursor-pointer" onClick={()=>variationMove(variationArray, i, i+1)}>
                                    <i className="ni ni-bold-down"></i>
                                  </div>
                                </th>
                                <td>{v.type.split('_').join(' ')}</td>
                                <td>{v.value}</td>
                                <td>
                                  <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.amount)}</span>
                                  <br/>
                                  <span>{new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.amount*rateUSDtoMYR))}</span>
                                </td>
                                <td><i 
                                  className="ni ni-fat-remove"
                                  style={{fontSize: '1rem', cursor:'pointer'}}
                                  onClick={() => removeVariation(i)}></i></td>
                              </tr>
                            )
                          }else{
                            return false
                          }
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              )
            }):null}
        </CardBody>
      </Card>
    </>
  );
}
 
export default Variations;