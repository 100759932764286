import firebase from "firebase/app";
import "firebase/storage";

// stage
// var firebaseConfig = {
//   apiKey: "AIzaSyCJuRr1CBvQewTHn5Y4pzRyr-ypq6XIvKw",
//   authDomain: "es-stage-project.firebaseapp.com",
//   projectId: "es-stage-project",
//   storageBucket: "es-stage-project.appspot.com",
//   messagingSenderId: "73634753505",
//   appId: "1:73634753505:web:efc97e6d8a6a0cffe6c0a0"
// };

// live
const firebaseConfig = {
  apiKey: "AIzaSyCOXlXZ1Truo8mCX8p9tt9fQF7fXbtx8o8",
  authDomain: "pracworks-admin.firebaseapp.com",
  projectId: "pracworks-admin",
  storageBucket: "pracworks-admin.appspot.com",
  messagingSenderId: "625034052089",
  appId: "1:625034052089:web:26a21c2cee5c29eb573d28"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default   };