import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchInvoices, pushInvoices, updateInvoices } from '../redux/actions/invoicesActions';
import moment from 'moment';
import sumBy from "lodash/sumBy"
import isEmpty from "lodash/isEmpty"
import { CSVDownload } from "react-csv";
import env from "../env.js"

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

import DateRange from "../components/Extra/DateRange.js"
import InvoiceModalView from "../components/Invoices/InvoiceModalView.js"
import InvoiceTrackingModal from "../components/Invoices/InvoiceTrackingModal.js"
import Notifications from "../components/Extra/Notifications.js"

import axios from "axios";
import client from "../feathers"

var pattern = "0000";
const PAGESIZE = 20;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Invoices = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ startDate, setStartDate ] = useState(false)
  const [ endDate, setEndDate ] = useState(false)
  const [ spin, setSpin ] = useState(false)
  const [ csvData, setCsvData ] = useState([])
  const [ csvProductData, setCsvProductData ] = useState([])

  // useEffect(() => {
  //   client.authenticate()
  //   .then(()=>{
  //     return client.service('invoices').create({})
  //   })
  //   .then((res) => {
  //     console.log(res)
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }, [])

  useEffect(() => {
    fetchData(props.fetchInvoices, notificationOpen, 0, 1)
  }, [props.fetchInvoices])

  const searchDate = (startDate, endDate) => {
    if(startDate && endDate){
      fetchData(props.fetchInvoices, notificationOpen, 0, 1, startDate._d, endDate._d)
    }
  }

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (fetchInvoices, notificationOpen, skip, currentPage, startDate, endDate) => {
    var query = {
      $limit: PAGESIZE,
      $skip: skip,
      $sort: {
        createdAt: -1
      }
    }

    if(startDate && endDate){
      query = {
        $and: [
          { 
            createdAt: {
              $gte: new Date(startDate).getTime()
            },
          },
          { 
            createdAt: {
              $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
            },
          }
        ],
        $limit: PAGESIZE,
        $skip: skip,
        $sort: {
          createdAt: -1
        }
      }
    }

    client.authenticate()
    .then(()=>{
      return client.service('invoices').find({
        query: query
      })
    })
    .then((res) => {
      setPagination(paginationCount(res.total, currentPage))
      fetchInvoices(res.data)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const downloadPdf = (v) => {

    v.shippingService = v.countryCode === 'MY'?
    "Local Express":  
    v.shippingCompany + "  Express"

    v.shippingMethod = v.countryCode === 'MY'?
    "-":  
    v.serviceCode

    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}v2/downloadPdf`, {
        params: {
          companyInfo: props.companyInfo, 
          details: v,
        },
      },
      {
        headers: {
          'Authorization': auth.accessToken
        },
      })
    })
    .then((response)=>{
      
      let name = (v.firstName?v.firstName:' ') + " " + (v.lastName?v.lastName:' ')
      let pdf_name = (props.companyInfo.companyName + "-" + v.invoicePrefix + " " + (pattern + v.invoiceNumber).slice(-4) + "" + (v.invoiceStatus?"" + v.invoiceStatus:"") + " " + name + ".pdf");

      fetch(`${client.io.io.uri}v2/getDownloadPdf`)
      .then(response => response.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = pdf_name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
      })
      .catch(err => console.error('Error:', err));

      // // Create a temporary URL for the blob
      // const url = window.URL.createObjectURL(new Blob([response.data]));

      // // Create a temporary link element
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', pdf_name);

      // // Trigger the download by simulating a click on the link
      // document.body.appendChild(link);
      // link.click();

      // // Clean up
      // window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);

      // props.history.push('/admin/downloadPdf', { pdf_name, html: response.data.html })

    })
    .catch((err)=>{
      console.log(err)
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const resendInvoice = (v) => {
    v.shippingService = v.countryCode === 'MY'?
    "Local Express":  
    `${v.shippingCompany} Express`

    v.shippingMethod = v.countryCode === 'MY'?
    "-":  
    v.serviceCode

    
    client.authenticate()
    .then((auth)=>{
      axios.post(`${client.io.io.uri}v2/downloadPdf`, {
        params: {
          companyInfo: props.companyInfo, 
          details: v,
          fileName: 'resend_invoice.pdf'
        },
      },
      {
        headers: {
          'Authorization': auth.accessToken
        },
      })
      return auth
    })
    .then((auth) => {
      return axios.post(`${client.io.io.uri}resendInvoice`, {
        params: {
          companyInfo: props.companyInfo, 
          details: v,
          mailTo: env.mailTo
        },
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      notificationOpen(true, 'success', "Invoice resent Successfully!") 
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const downloadCSV = async() => {
    var tmpData = []
    var tmpProductData = []
    setSpin(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          $sort: {
            createdAt: -1
          }
        }
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('invoices').find({
            query: query
          })
        })
        .then((res) => {
          var pattern = "0000";
          let d = []
          let dP = []
           
          for(let i = 0; i < res.data.length; i++){
            d.push({
              invoiceNumber:  res.data[i].invoicePrefix + " " + (pattern + res.data[i].invoiceNumber).slice(-4) + " " + (res.data[i].invoiceStatus?" " + res.data[i].invoiceStatus:""),
              address: res.data[i].address,
              country: res.data[i].country,
              state: res.data[i].state,
              postalCode: res.data[i].postalCode,
              currency: res.data[i].currency,
              email: res.data[i].email,
              firstName: res.data[i].firstName,
              lastName: res.data[i].lastName,
              phoneNumber: res.data[i].phoneNumber,
              // purchaseItems: [{…}],
              shippingAmount: res.data[i].shippingAmount,
              status: res.data[i].status,
              totalAmount: res.data[i].totalAmount,
              totalItemsAmount: res.data[i].totalItemsAmount,
              trackingNumber: res.data[i].trackingNumber,
              createdAt: moment(res.data[i].createdAt).format("MMM Do YYYY"),
              updatedAt: moment(res.data[i].updatedAt).format("MMM Do YYYY")
            })

            for(let j = 0; j < res.data[i].purchaseItems.length; j++){ 
              let productRec = res.data[i].purchaseItems[j]
              
              let varia = ""
              for(let k = 0; k < productRec.variations.length; k++){ 
                // delete productRec.variations[k]._id
                // delete productRec.variations[k].amount
                varia = 'type: ' + productRec.variations[k].type + ', ' + 
                'value: ' + productRec.variations[k].value + ', ' +
                'totalAmount: ' + productRec.variations[k].totalAmount  + ' - '
              }
              dP.push({
                name: productRec.name,
                invoiceNumber:  res.data[i].invoicePrefix + " " + (pattern + res.data[i].invoiceNumber).slice(-4) + " " + (res.data[i].invoiceStatus?" " + res.data[i].invoiceStatus:""),
                perUnitAmount: productRec.perUnitAmount,
                productCode: productRec.productCode,
                variations: varia,
                currency: res.data[i].currency,
                quantity: productRec.quantity,
                totalNetAmount: productRec.totalNetAmount+(!isEmpty(productRec.variations)?sumBy(productRec.variations, "totalAmount"):0)
              })
            }
          }
          
          tmpData = tmpData.concat(d)
          tmpProductData = tmpProductData.concat(dP)
          resolve(res.data)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            notificationOpen(true, 'danger', "Please Sign-in to continue!")
          }else{
            notificationOpen(true, 'danger', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setCsvProductData(tmpProductData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }
  }
  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.fetchInvoices, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.fetchInvoices, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.fetchInvoices, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <span className="mask bg-gradient-default opacity-6" />
        <Container className="d-flex align-items-center" fluid>
          <Row className="w-100 justify-content-center text-center m-auto">
            <Col md="8">
              <h1 className="text-white">
                Search by Date
              </h1>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate} 
                searchDate={searchDate}
              />
            </Col>
          </Row>
        </Container>
        <Container className="d-flex align-items-center" fluid>
          <Row className="w-100 justify-content-center text-center m-auto">
            <Col md="8">
              <Button 
                type="button" 
                color="primary" 
                className="bg-gradient-primary border-0"
                disabled={spin}
                onClick={downloadCSV}
              >
                <div className="d-flex align-items-center">
                {
                spin? (
                  <Spinner className="mr-2" style={{height: 15, width: 15}}>
                    Loading...
                  </Spinner>
                  ): null
                }
                  Download CSV
                </div>
              </Button>
              <div>
                <p className="text-white font-weight-500">
                  {
                    (startDate && endDate)? 
                    moment(startDate).format("MMM Do YYYY") + ' - ' + moment(endDate).format("MMM Do YYYY") :
                    "All Records"
                  }
                </p>
              </div>
              {
                spin? (
                  <>
                    <CSVDownload data={csvData} target="_blank" />
                    <CSVDownload data={csvProductData} target="_blank" />
                  </>
                ): null
              }
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Invoices tables</h3>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Inv.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Tracking Num.</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Shipping Com.</th>
                      <th scope="col">Items Amt.</th>
                      <th scope="col">Shipping Amt.</th>
                      <th scope="col">Total Amt.</th>
                      <th scope="col">Created</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props.invoicesData.length > 0? (
                      props.invoicesData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              {v.invoicePrefix + " " + (pattern + v.invoiceNumber).slice(-4) + " " + (v.invoiceStatus?" " + v.invoiceStatus:"")}
                            </th>
                            <td>
                              {v.firstName?v.firstName:' '}{" "}
                              {v.lastName?v.lastName:' '}
                            </td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.trackingNumber? "bg-success": "bg-danger"} />
                                {v.trackingNumber?"Completed":"Pending"}
                              </Badge>
                            </td>
                            <td>
                              {v.trackingNumber?v.trackingNumber:'-'}
                            </td>
                            <td>{v.currency}</td>
                            <td>{v.shippingCompany}</td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalItemsAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalItemsAmount)
                              }
                            </td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.shippingAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.shippingAmount)
                              }
                            </td>
                            <td>
                              {v.currency === "USD"?
                              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v.totalAmount)
                              :
                              new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(v.totalAmount)
                              }
                            </td>
                            <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModal()}}
                                  >
                                    View
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Update Tracking Number
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {resendInvoice(v)}}
                                  >
                                    Resend Invoice
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {downloadPdf(v)}}
                                  >
                                    Download Pdf
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })
                    ): <span style={{padding: '0px 10px'}}>No Record Found!</span>}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <InvoiceModalView
        dataEdit={dataEdit} 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
      />
      <InvoiceTrackingModal 
        dataEdit={dataEdit}
        invoiceNumber={dataEdit.invoicePrefix + " " + (pattern + dataEdit.invoiceNumber).slice(-4) + " " + (dataEdit.invoiceStatus?" " + dataEdit.invoiceStatus:"")}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen}
        updateInvoices={props.updateInvoices}
        companyInfo={props.companyInfo}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  invoicesData: state.invoices.data,
  companyInfo: state.company.data,
});

const mapDispatchToProps = {
  fetchInvoices: fetchInvoices, 
  pushInvoices: pushInvoices, 
  updateInvoices: updateInvoices
};
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);