//Action Types
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const PUSH_TRANSACTIONS = 'PUSH_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const REMOVE_TRANSACTIONS = 'REMOVE_TRANSACTIONS';

//Action Creator
export const fetchTransactions = (data) => ({
   type: FETCH_TRANSACTIONS,
   data: data
});

export const pushTransactions = (data) => ({
    type: PUSH_TRANSACTIONS,
    data: data
 });

export const updateTransactions = (data) => ({
   type: UPDATE_TRANSACTIONS,
   data: data
});

export const removeTransactions = (data) => ({
    type: REMOVE_TRANSACTIONS,
    data: data
});