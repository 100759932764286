import roleReducer from './roleReducer';
import productsReducer from './productsReducer';
import usersReducer from './usersReducer';
import companyReducer from './companyReducer';
import invoicesReducer from './invoicesReducer';
import transactionsReducer from './transactionsReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    role: roleReducer,
    products: productsReducer,
    users: usersReducer,
    company: companyReducer,
    invoices: invoicesReducer,
    transactions: transactionsReducer,
});

export default rootReducer;