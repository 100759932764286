
// mails to
const mailTo = [
  'Inquiries@pracworks.com.my',
  // 'w.leaf12@gmail.com',
]

const env = {
  mailTo: mailTo,
}

export default env;