import React from "react";
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const DateRange = ({startDate, endDate, setStartDate, setEndDate, searchDate}) => {
  return (  
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date From"
                }}
                value={startDate}
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(startDate._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(endDate._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    endDate &&
                    endDate._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={e => {
                  searchDate(e, endDate)
                  setStartDate(e)
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date To"
                }}
                value={endDate}
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(startDate._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(endDate._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    endDate &&
                    endDate._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={e => {
                  searchDate(startDate, e)
                  setEndDate(e)
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}
 
export default DateRange;