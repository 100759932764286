import {
  FETCH_USERS,
  PUSH_USERS,
  UPDATE_USERS,
  REMOVE_USERS,
 } from '../actions/usersActions';

const INITIAL_STATE = {
  data: [],
}

export default function usersReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_USERS:
      return {
        ...state,
        data:action.data,
      }
    case PUSH_USERS:
      return {
        ...state,
        data:[action.data, ...state.data],
      }
    case UPDATE_USERS:
      let updated = state.data.map((v)=>{
        if(v._id === action.data._id) {
          return action.data
        } else return v
      })
      return {
        ...state,
        data: updated
      }
    case REMOVE_USERS:
      return {
        ...state,
        data: [...state.data.filter( (item) => item._id !== action.data._id)]
      }
    default:
      return state

  }
}