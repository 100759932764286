import React from "react";

// reactstrap components
import { 
  UncontrolledTooltip,
  Card, 
  CardBody, 
  CardTitle, 
  Row, 
  Col 
} from "reactstrap";

const HeaderBoxes = ({totalAmount, totalOrders, rateUSDtoMYR}) => {
  return (
    <>
      <Row className="mb-4 justify-content-center">
        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total Amount(USD)
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                      totalAmount
                    )}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="fas fa-chart-bar" />
                  </div>
                </Col>
              </Row>
              {/* <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-success mr-2">
                  <i className="fa fa-arrow-up" /> 3.48%
                </span>{" "}
                <span className="text-nowrap">Since last month</span>
              </p> */}
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total Amount (MYR)
                    <span className="d-inline-block ml-2">
                      <div id="tooltipAmountMYR" className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                        </svg>
                      </div>
                      <UncontrolledTooltip delay={0} target="tooltipAmountMYR">
                        Notice - This Amount is for reference only. It is not an accurate MYR price.
                      </UncontrolledTooltip>
                    </span>
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format(
                      (totalAmount*rateUSDtoMYR)
                    )}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i className="fas fa-chart-pie" />
                  </div>
                </Col>
              </Row>
              {/* <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-danger mr-2">
                  <i className="fas fa-arrow-down" /> 3.48%
                </span>{" "}
                <span className="text-nowrap">Since last week</span>
              </p> */}
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Invoices
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {totalOrders}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                    <i className="ni ni-single-copy-04" />
                  </div>
                </Col>
              </Row>
              {/* <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-warning mr-2">
                  <i className="fas fa-arrow-down" /> 1.10%
                </span>{" "}
                <span className="text-nowrap">Since yesterday</span>
              </p> */}
            </CardBody>
          </Card>
        </Col>
        {/* <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Performance
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">49,65%</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i className="fas fa-percent" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-success mr-2">
                  <i className="fas fa-arrow-up" /> 12%
                </span>{" "}
                <span className="text-nowrap">Since last month</span>
              </p>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default HeaderBoxes;
