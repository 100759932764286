//Action Types
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const PUSH_INVOICES = 'PUSH_INVOICES';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const REMOVE_INVOICES = 'REMOVE_INVOICES';

//Action Creator
export const fetchInvoices = (data) => ({
   type: FETCH_INVOICES,
   data: data
});

export const pushInvoices = (data) => ({
    type: PUSH_INVOICES,
    data: data
 });

export const updateInvoices = (data) => ({
   type: UPDATE_INVOICES,
   data: data
});

export const removeInvoices = (data) => ({
    type: REMOVE_INVOICES,
    data: data
});