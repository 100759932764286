import Index from "./views/Index.js";
import Users from "./views/Users.js";
import Products from "./views/Products.js";
import Invoices from "./views/Invoices.js";
import Transactions from "./views/Transactions.js";
import Profile from "./views/Profile.js";
import CompanySetting from "./views/CompanySetting.js";
// import Register from "./views/Register.js";
import Login from "./views/Login.js";
import DownloadPdf from "./views/DownloadPdf.js";

// import Maps from "views/examples/Maps.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    show: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin",
    show: true,
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-box-2 text-default",
    component: Products,
    layout: "/admin",
    show: true,
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: "ni ni-single-copy-04 text-primary",
    component: Invoices,
    layout: "/admin",
    show: true,
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "ni ni-chart-bar-32 text-info",
    component: Transactions,
    layout: "/admin",
    show: true,
  },
  {
    path: "/setting",
    name: "Setting",
    icon: "ni ni-settings text-red",
    component: CompanySetting,
    layout: "/admin",
    show: true,
  },
  {
    path: "/user-profile/:username",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    show: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    show: false,
  },
  {
    path: "/downloadPdf",
    name: "downloadPdf",
    icon: "ni ni-key-25 text-info",
    component: DownloadPdf,
    layout: "/admin",
    show: false,
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
