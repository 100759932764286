import React from "react";
import { Formik } from 'formik';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";

import axios from "axios";
import env from "../../env.js"
import client from "../../feathers.js"

var pattern = "0000";

const InvoiceTrackingModal = (props) => {

  const onSubmit = async(value) => {
    client.authenticate()
    .then((auth)=>{
      return client.service('invoices').patch(props.dataEdit._id, value)
      .then((res) => {
        sendTrackingNumber(auth.accessToken, res._id, value, res.email)
        props.toggleModal()
        props.updateInvoices(res)
        props.notificationOpen(true, 'success', "Invoice " + res.invoicePrefix + " " + (pattern + res.invoiceNumber).slice(-4) + (res.invoiceStatus?" " + res.invoiceStatus:"") + " Updated Successfully!")        
      })
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const sendTrackingNumber = (accessToken, invId, value, email) => {
    return axios.post(`${client.io.io.uri}sendTrackingNumber`, {
      params: {
        companyInfo: props.companyInfo, 
        invId,
        trackingNumber: value.trackingNumber,
        mailTo: env.mailTo.concat(email)
      },
    },
    {
      headers: {
        'Authorization': accessToken
      }
    })
  }

  return (  
    <>
      <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Update Tracking Number ({props.invoiceNumber}) (
              {props.dataEdit.firstName?props.dataEdit.firstName:' '}{" "}
              {props.dataEdit.lastName?props.dataEdit.lastName:' '}
            )
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            trackingNumber: props.dataEdit.trackingNumber || '',
          }}
          onSubmit={onSubmit}
        >
          {propsChild => {
            const {
              values,
              handleChange,
              handleSubmit,
            } = propsChild;
            return (
              <Form role="form" onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Tracking Number
                    </small>
                  </div>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Tracking Number"
                        type="text"
                        id="trackingNumber"
                        value={values.trackingNumber}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModal}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
              );
            }}
          </Formik>
      </Modal>
    </>
  );
}
 
export default InvoiceTrackingModal;